import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// MODELOS
import {AppConstants, IItemModel, KeyValue} from './../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { StorageService } from './storage.service';
import { ILine } from '../models/i-line';
import {Observable, of} from 'rxjs';
import {IIPriceResponse, IITemResponse, IItemsNamesResponse, IPagedRequestResponse} from '../models/responses';
import { IItemDetail } from '../models/i-itemDetail';
import { IResponse } from '../models/i-api-response';
import { ISpeciality } from '../models/i-speciality';
import { IMinifiedWarehouse } from '../models/i-minified-warehouse';
import {IItemWarehouseInfo, IMinifiedItem, IMinifiedItemForInventoryCounting} from "../models/i-item";
import {IService} from "../models/i-service";
import {IBinLocation} from "../models/i-bin-location";
import {IBarcode} from "../models/i-barcode";
import {IMeasurementUnit} from "../models/i-measurement-unit";
import {KeyValuePair} from "../models/key-value";

// PI ES

@Injectable({
  providedIn: 'root'
})
export class ItemService implements IService{

  // readonly apiUrl = 'http://localhost:50001/api/';

  hasLines: boolean;
  private lines: ILine[];

  constructor(private http: HttpClient,
    private storage: StorageService) {
  }

  GetDataForGoodReceiptInvocie(ItemCodes: string[],WhsCode:string) {

    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetItemDataForGoodReceiptInvoice?WhsCode=${WhsCode}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<IResponse<any>>(url,ItemCodes, { headers });
  }


  GetItemLastPrice(Item: any): any {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetItemLastPurchagePrice?ItemCode=${Item}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<IResponse<any>>(url, { headers });
  }



  GetItemAVGPrice(Item: string): any {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetItemAVGPrice?ItemCode=${Item}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<IResponse<any>>(url, { headers });
  }


  /**
   * Send a request to retrieve the items that match with the specified specialty and category
   * @param pSpecialty Specialty that items must have.
   * @param pCategory Category that items must have.
   * @constructor
   */
  GetItems(pSpecialty: string = '', pCategory: string = ''): Observable<IItemsNamesResponse> {
    return this.http.get<IItemsNamesResponse>("api/Items/GetItemNames", {
      params: {
        specialty: pSpecialty,
        category: pCategory
      }
    });
  }

  GetPayTerms() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetPayTermsList`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(url, { headers });
  }

  GetPriceList() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetPriceList`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<any>(url, { headers });
  }

  GetPriceListDefault(_cardCode: string): Observable<IIPriceResponse> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetDefaultPriceList?cardCode=${_cardCode}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<IIPriceResponse>(url, { headers });
  }

  /**
   * Send a request to retrieve the product details
   * @param pItemCode Code of the product that will be retrieved
   * @param pPriceListNum Selected price list number
   * @param pWhsCode Code of the warehouse
   * @param pCardCode Code of the customer
   * @param pCategory Represent the category selected in the document
   * @param pMedismartCustomer Indicates if the selected customer is of Medismart
   * @constructor
   */
  GetItemByItemCode(pItemCode: string, pPriceListNum: number, pWhsCode: string, pCardCode: string = 'hardcoded', pCategory: string = '', pMedismartCustomer: boolean = false): Observable<IITemResponse>
  {
    return this.http.get<IITemResponse>('api/Items/GetInfoItem', {
      params: {
        ItemCode: pItemCode,
        priceList: pPriceListNum.toString(),
        cardCode: pCardCode,
        whCode: pWhsCode,
        category: pCategory,
        medismartCustomer: pMedismartCustomer.toString()
      }
    });
  }

  // funcion para obtener la informacion de los disponibles de un item en los almacenes
  // recibe como parametro el codigo del producto
  GetWHAvailableItem(itemCode: string, _headquarterId: number = 0, _speciality: string = '') {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetWHAvailableItem?ItemCode=${itemCode}&headquarterId=${_headquarterId}&speciality=${_speciality}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(url, { headers });
  }

  // funcion para obtener la informacion de las series del item en un almacen
  // recibe como parametro el codigo del producto
  GetSeriesByItem(itemCode: string, WhsCode: string) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetSeriesByItem?ItemCode=${itemCode}&WhsCode=${WhsCode}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(url, { headers });
  }

  // Crea un item
  CrateItem(_item: IItemModel) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/create`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<any>(url, _item, { headers });
  }
  // Edita un modelo
  UpdateItem(_item: IItemModel) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/updateItem`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<any>(url, _item, { headers });
  }
  // Obtiene el siguiente codigo para crear un item
  getNextCode() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/getNextCode`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<any>(url, { headers });
  }

  getItemPriceList(_itemCode: string) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetItemPriceList?_itemCode=${_itemCode}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<any>(url, { headers });
  }

  GetBarcodesByItem(_itemCode: string) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetBarcodesByItem?_itemCode=${_itemCode}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<any>(url, { headers });
  }

  GetLines(data: any): ILine[] {
    return this.lines;
  }

  UpdateLines(lines: ILine[]): void {
    this.lines = lines;
  }


  // Metodo para obtener el detail de un item en modo consulta de entrada de mercaderia
  GetItemDetails(ItemCode: string, Cantidad: number, docType: number) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetItemDetail?ItemCode=${ItemCode}&NumeroEntradas=${Cantidad}&DocType=${docType}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<any>(url, { headers });
  }

  GetSpecialities(): Observable<IResponse<ISpeciality[]>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetItemSpecialities`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<IResponse<ISpeciality[]>>(url, { headers });
  }

  GetWHAvailable(_headquarterId: number = 0, _speciality: string = ''): Observable<IResponse<IMinifiedWarehouse[]>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetWHAvailable?headquarterId=${_headquarterId}&speciality=${_speciality}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<IResponse<IMinifiedWarehouse[]>>(url, { headers });
  }

  GetMinifiedItems(pTake: number, pSkip: number, pSearchCriteria: string, pType: string): Observable<IResponse<IPagedRequestResponse<IMinifiedItem[] | IMinifiedItemForInventoryCounting[]>>> {
    return this.http.get<IResponse<IPagedRequestResponse<IMinifiedItem[]>>>(`api/Items/Minified`, {
      params: {
        searchCriteria: pSearchCriteria,
        take: pTake.toString(),
        skip: pSkip.toString(),
        type: pType
      }
    });
  }

  GetItemsForInventoryCounting(pTake: number, pSkip: number, pSearchCriteria: string, pBarCode: string): Observable<IResponse<IPagedRequestResponse<IMinifiedItemForInventoryCounting[]>>>
  {
    return this.http.get<IResponse<IPagedRequestResponse<IMinifiedItemForInventoryCounting[]>>>(`api/Items/GetMinifiedItemsForInventoryCounting`, {
      params: {
        searchCriteria: pSearchCriteria,
        take: pTake.toString(),
        skip: pSkip.toString(),
        searchByBarcode: pBarCode
      }
    });
  }

  /**
   * Send a request to retrieve the item warehouse information
   * @param _itemCode The item to retrieve the warehouse information
   * @param _whsCode The warehouse to get the information
   * @constructor
   */
  GetItemWarehouseInfo(_itemCode: string, _whsCode: string): Observable<IResponse<IItemWarehouseInfo>>
  {
    return this.http.get<IResponse<IItemWarehouseInfo>>(`api/Items/${_itemCode}/Warehouse/${_whsCode}/Info`);
  }

  /**
   * Send a request to retrieve the measurement units of the item
   * @param _itemCode The item to get the measurement units
   * @constructor
   */
  GetItemMeasurementUnits(_itemCode: string): Observable<IResponse<IMeasurementUnit[]>>
  {
    return this.http.get<IResponse<IMeasurementUnit[]>>(`api/Items/${_itemCode}/MeasurementUnits`);
  }

  /**
   * Send a request to retrieve the barcodes of the item
   * @param _itemCode The item to get the measurement units
   * @constructor
   */
  GetBarcodes(_itemCode: string): Observable<IResponse<IBarcode[]>>
  {
    return this.http.get<IResponse<IBarcode[]>>(`api/Items/${_itemCode}/BarCodes`);
  }

  /**
   * Send a request to retrieve an item by it's barcode
   * @param _barcode The barcode of the item to retrieve
   * @constructor
   */
  GetItemByBarcode(_barcode: string): Observable<IResponse<IMinifiedItemForInventoryCounting>>
  {
    return this.http.get<IResponse<IMinifiedItemForInventoryCounting>>(`api/Items/${_barcode}`);
  }

  /**
   * Retrieve the categories that are configured in the categories by specialty and have the specified specialty
   * @param pSpecialty Selected specialty to filter the categories
   * @constructor
   */
  GetSpecialtyCategories(pSpecialty: string): Observable<IResponse<KeyValuePair<string, string>[]>>
  {
    let endpoint = `api/Items/Specialty/${pSpecialty}/Categories`;

    if(!pSpecialty)
    {
      endpoint = `api/Items/Specialty/Categories`
    }

    return this.http.get<IResponse<KeyValuePair<string, string>[]>>(endpoint);
  }
}
