/**
 * Represent the variables of the local storage
 */
export enum LocalStorageVariables
{
  /**
   * Represent the current user assigned sales person
   */
  CurrentUserSalesPerson = 'CurrentUserSalesPerson',
  /**
   * Represent the selected headquarter
   */
  CurrentHeadquarter = 'currentHeadquarter'
}
