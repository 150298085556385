import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {AlertService, AuthenticationService, CommonService, PermsService, StorageService} from "../../../services";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {of, Subscription} from "rxjs";
import {formatDate} from "@angular/common";
import {
  DeliveryAuthorization,
  DeliveryErrorType,
  DeliveryReasons,
  DeliveryStatus
} from "../../../enum/enum";
import {IDelivery, IDeliveryDataMaster} from "../../../models/i-delivery";
import {finalize, timeout} from "rxjs/operators";
import {DeliveryService} from "../../../services/delivery.service";
import {HeadquarterService} from "../../../services/headquarter.service";
import {IHeadquarter} from "../../../models/i-headquarter";
import {ActivatedRoute} from "@angular/router";
import {IResponse} from "../../../models/i-api-response";
import {IPermission} from "../../../models";
import {NgbModal, NgbModalOptions, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {HeadquarterSelectorComponent} from "../../../components/headquarter-selector/headquarter-selector.component";

const FILTER_PAG_REGEX = /[^0-9]/g;

@Component({
  selector: 'app-deliveries',
  templateUrl: './deliveries.component.html',
  styleUrls: ['./deliveries.component.scss']
})
export class DeliveriesComponent implements OnInit {

  @ViewChild('deliveryModal') deliveryModal: ElementRef<HTMLInputElement>;
  deliveryModalRef: NgbModalRef;

  @ViewChild('authModal') authModal: ElementRef<HTMLInputElement>;
  authModalRef: NgbModalRef;

  @BlockUI() blockUI: NgBlockUI;
  permisos = true;
  canEdit: boolean = false;
  currentUser: any; // variable para almacenar el usuario actual
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual
  deliveriesDataMaster: IDeliveryDataMaster[];
  event: any;
  delivery: IDelivery;
  deliveries: IDelivery[];
  searchForm: FormGroup;
  page: number = 1;
  pageSize: number = 7;
  fullSize: number = 0;
  deliveryStatusList = Object.keys(DeliveryStatus).map(key => ({ key: key, value: DeliveryStatus[key] }));
  deliveryErrorTypesList = Object.keys(DeliveryErrorType).map(key => ({ key: key, value: DeliveryErrorType[key] }));
  deliveryReasonsList = Object.keys(DeliveryReasons).map(key => ({ key: key, value: DeliveryReasons[key] }));
  deliveryAuthorizationList = Object.keys(DeliveryAuthorization).map(key => ({ key: key, value: DeliveryAuthorization[key] }));
  headquarters: IHeadquarter[];
  deliveryForm: FormGroup;
  deliveryLabOrderNumber: string = '';
  authorizationModalMessage: string;
  userCredentialsForm: FormGroup;
  authorizatorPermissions: IPermission[];

  constructor(
    private commonService: CommonService,
    private alertService: AlertService,
    private permissionService: PermsService,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private deliveryService: DeliveryService,
    private headquarterService: HeadquarterService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private changeDetectorRef: ChangeDetectorRef,
    private storage: StorageService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.authorizatorPermissions = [];
    this.deliveryLabOrderNumber = this.route.snapshot.params['id'];
    this.deliveriesDataMaster = [];
    this.headquarters = [];
    this.CheckPermits();
    this.ResetSearchForm();
    this.ResetDeliveryForm();
    this.LoadHeadquarters();
  }

  CheckPermits() {
    this.permissionService.getPerms(this.currentUser.userId).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.Result) {
        data.perms.forEach(Perm => {
          if (Perm.Name === 'V_Deliveries') {
            this.permisos = Perm.Active;
          }
          if (Perm.Name === 'Sales_DeliveryControl_Edit'){
            this.canEdit = Perm.Active;
          }
        });
        this.authorizatorPermissions = data.perms.filter(x => x.Active);
      } else {
        this.permisos = false;
      }
    }, error => {
      this.permisos = false;
      this.blockUI.stop();
    });
  }

  ResetSearchForm(): void{
    this.searchForm = this.formBuilder.group({
      Patient: [''],
      LabOrderNumber: [''],
      InvoiceNumber: [''],
      Headquarter: ['0', Validators.required],
      Status: ['', Validators.required],
      ErrorType: ['', Validators.required],
      Reason: ['', Validators.required],
      Authorization: ['', Validators.required],
      From: [formatDate(new Date(), 'yyyy-MM-dd','en'), Validators.required],
      To: [formatDate(new Date(), 'yyyy-MM-dd','en'), Validators.required]
    });
  }

  ResetDeliveryForm(): void{
    this.deliveryForm = this.formBuilder.group({
      U_CreateDate: [{value: new Date(), disabled: true}, Validators.required],
      U_CreateTime: ['', Validators.required],
      U_UpdateDate: [{value: new Date(), disabled: true}, Validators.required],
      U_UpdateTime: ['', Validators.required],
      U_DocEntry: [0, Validators.required],
      U_DocNum: [0, Validators.required],
      BusinessPartner: [{value: '', disabled: true}, Validators.required],
      U_CardCode: ['', Validators.required],
      U_CardName: ['', Validators.required],
      U_OrigLabOrderNumber: [{value: '', disabled: true}, Validators.required],
      U_RectLabOrderNumber: [{value: '', disabled: true}, Validators.required],
      U_Headquarter: [{value: 0, disabled: true}, Validators.required],
      U_Status: [{value: '', disabled: false}, Validators.required],
      U_ErrorType: [{value: '', disabled: true}, Validators.required],
      U_Reason: [{value: '', disabled: true}, Validators.required],
      U_Notes: ['', Validators.required],
      U_Authorization: [{value: '', disabled: true}, Validators.required],
      U_Error: ['', Validators.required]
    });
  }

  FormatDeliveryStatus(_status: string): string {
    switch (_status) {
      case '':
        return 'No seleccionado';
      case DeliveryStatus.Open:
        return 'Abierta';
      case DeliveryStatus.Laboratory:
        return 'Enviado a laboratorio';
      case DeliveryStatus.Received:
        return 'Recibido en sucursal';
      case DeliveryStatus.Rectification:
        return 'Solicitar rectificación';
      case DeliveryStatus.Delivered:
        return 'Entregado';
      default:
        return 'Desconocido';
    }
  }

  FormatDeliveryErrorType(_errorType: string): string {
    switch (_errorType) {
      case '':
        return 'No seleccionado';
      case DeliveryErrorType.Nothing:
        return 'Ninguno';
      case DeliveryErrorType.Laboratory:
        return 'Laboratorio';
      case DeliveryErrorType.Optometry:
        return 'Optometra';
      case DeliveryErrorType.Vendor:
        return 'Vendedor';
      default:
        return 'Desconocido';
    }
  }

  FormatDeliveryReason(_reason: string): string {
    switch (_reason) {
      case '':
        return 'No seleccionada';
      case DeliveryReasons.IncorrectGraduation:
        return 'Graduación incorrecta';
      case DeliveryReasons.IncorrectOpticCentersOrHeights:
        return 'Centros ópticos o alturas incorrectos';
      case DeliveryReasons.IncorrectMaterial:
        return 'Material incorrecto';
      case DeliveryReasons.ScratchedGlasses:
        return 'Lentes rayados';
      case DeliveryReasons.GlassesReprocessCauseByHoopBroken:
        return 'Reproceso de lentes por quiebra de aro';
      case DeliveryReasons.HoopsConfusion:
      case DeliveryReasons.HoopsConfusion2:
        return 'Confusión de aros';
      case DeliveryReasons.IncorrectDesign:
        return 'Diseño incorrecto';
      case DeliveryReasons.GraduationChange:
        return 'Cambio de graduación';
      case DeliveryReasons.BadMaterialRecomendation:
      case DeliveryReasons.BadMaterialRecomendation2:
        return 'Mala Recomendación del material';
      case DeliveryReasons.ScratchedGlassesOnLensometer:
        return 'Lentes rayados en el lensómetro';
      case DeliveryReasons.BadDesignRecomendation:
        return 'Mala Recomendación de diseño';
      case DeliveryReasons.OmissionOrIncorrectTreatmentRecommendation:
        return 'Omisión o recomendación de tratamientos incorrectos';
      case DeliveryReasons.WrongHoopsApprobation:
        return 'Aprobación de aros inadecuados';
      case DeliveryReasons.BadPrescriptionTranscription:
        return 'Mala transcripción de receta';
      case DeliveryReasons.IncorrectTakeOfOpticCentersAndHight:
        return 'Toma incorrecta de centros ópticos y alturas';
      case DeliveryReasons.IncorrectGlassesEfectiveDiameter:
        return 'Diámetro efectivo de los lentes incorrecto';
      case DeliveryReasons.UnreadableSymbolsAndNumbersOnTheOrder:
        return 'Números Símbolos ilegibles en la orden';
      case DeliveryReasons.BadHoopsRecomendation:
        return 'Mala recomendación de aro al paciente';
      default:
        return 'Desconocido';
    }
  }

  FormatDeliveryAuthorization(_authorization: string): string {
    switch (_authorization) {
      case '':
        return 'No seleccionado';
      case DeliveryAuthorization.Request:
        return 'Solicitada';
      case DeliveryAuthorization.Approved:
        return 'Aprobada';
      case DeliveryAuthorization.Rejected:
        return 'Rechazada';
      default:
        return 'Desconocido';
    }
  }

  FormatDeliveryAuthorizationFilter(_authorization: string): string {
    switch (_authorization) {
      case '':
        return 'No seleccionado';
      case DeliveryAuthorization.Request:
        return 'Solicitadas';
      case DeliveryAuthorization.Approved:
        return 'Aprobadas';
      case DeliveryAuthorization.Rejected:
        return 'Rechazadas';
      default:
        return 'Desconocido';
    }
  }

  ParseDate(dateString: string): Date {
    const [year, month, day] = dateString.split('-');
    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  SearchDeliveries(_keepPagination: boolean = false): void {
    if(!_keepPagination){
      this.page = 1;
    }
    try {
      this.blockUI.start('Buscando...');
      this.deliveryService.GetDeliveries(
        this.searchForm.controls['Patient'].value,
        this.searchForm.controls['LabOrderNumber'].value,
        this.searchForm.controls['InvoiceNumber'].value,
        this.searchForm.controls['Headquarter'].value,
        this.searchForm.controls['Status'].value,
        this.searchForm.controls['ErrorType'].value,
        this.searchForm.controls['Reason'].value,
        this.searchForm.controls['Authorization'].value,
        this.ParseDate(this.searchForm.controls['From'].value),
        this.ParseDate(this.searchForm.controls['To'].value),
        (this.page - 1) * this.pageSize,
        this.pageSize
      )
        .pipe(finalize(() => this.blockUI.stop()))
        .subscribe({
          next: (result) => {
            if (result.Result) {
              this.deliveriesDataMaster = result.Data.DeliveriesSlice.reduce((acc, cur) => {
                let deliveryDataMaster = acc.find(x => x.U_OrigLabOrderNumber === cur.U_OrigLabOrderNumber);
                if(!deliveryDataMaster){
                  deliveryDataMaster = {...cur, Deliveries: []} as IDeliveryDataMaster;
                  acc.push(deliveryDataMaster);
                }
                deliveryDataMaster.Deliveries.push(cur);
                return acc;
              }, [] as IDeliveryDataMaster[]);

              this.fullSize = result.Data.FullSize;
            } else {
              console.log(result.Error);
            }
          },
          error: (err) => {
            console.log(err);
          },
        });
    } catch (ex) {
      this.blockUI.stop();
      console.log(ex);
    }
  }

  LoadHeadquarters(): void{
    this.blockUI.start('Cargando listas de sedes...');
    this.headquarterService.GetHeadquarters().pipe(finalize(()=>this.blockUI.stop())).subscribe(callBack => {
      if (callBack.Result) {
        this.headquarters = [];
        this.headquarters.push({ Id: 0, Code: 'Todas', Name:'Todas las sedes', SeriesByHeadquarters: [], Status: 'A' })
        this.headquarters = this.headquarters.concat(callBack.Data);
        this.SearchDeliveries();
        if(this.deliveryLabOrderNumber){
          this.LoadCurrentDelivery(this.deliveryLabOrderNumber);
        }
      } else {
        this.alertService.errorInfoAlert('Error al cargar la lista de sedes - ' + callBack.Error.Message);
      }
    }, error => {
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    });
  }

  LoadCurrentDelivery(_deliveryLabOrderNumber: string): void{
    try{
      this.blockUI.start('Cargando entrega...');
      this.deliveryService.GetDeliveriesByLabOrderNumber(_deliveryLabOrderNumber)
        .pipe(finalize(() => this.blockUI.stop()))
        .subscribe({
          next: (result) => {
            if (result.Result) {
              if(result.Data){
                this.modalService.dismissAll('abort');

                let deliveriesDataMaster = result.Data.reduce((acc, cur) => {
                  acc = acc || {...cur, Deliveries: []} as IDeliveryDataMaster;
                  acc.Deliveries.push(cur);
                  return acc;
                }, null as IDeliveryDataMaster);

                this.ShowDeliveryDetails(this.deliveryModal, deliveriesDataMaster);

                if(this.deliveryLabOrderNumber){
                  if(this.storage.GetCurrentHeadquarter() === null && this.authenticationService.currentUser){
                    setTimeout(() => {
                      this.OpenHeadquarterSelector();
                    });
                  }
                }
              } else {
                this.alertService.errorInfoAlert("La entrega no existe");
              }
            } else {
              console.log(result.Error);
            }
          },
          error: (err) => {
            console.log(err);
          },
        });
    } catch (ex) {
      this.blockUI.stop();
      console.log(ex);
    }
  }

  ShowDeliveryDetails(_content, _deliveryDataMaster: IDeliveryDataMaster): void {
    try {
      this.ResetDeliveryForm();
      _deliveryDataMaster.Deliveries.forEach(d => {
        if(d.U_Status != DeliveryStatus.Rectification){
          d['dErrorType'] = true;
          d['dAuthorization'] = true;
        }

        if(d.U_ErrorType == DeliveryErrorType.Nothing){
          d['dReason'] = true;
        }

        if(d.U_ErrorType != DeliveryErrorType.Optometry && d.U_ErrorType != DeliveryErrorType.Vendor){
          d['dRectLabOrderNumber'] = true;
        }
      })

      this.deliveryForm.patchValue({
        ..._deliveryDataMaster,
        BusinessPartner: `${_deliveryDataMaster.U_CardCode} - ${_deliveryDataMaster.U_CardName}`
      });

      this.deliveries = _deliveryDataMaster.Deliveries;

      if(!this.canEdit){
        _deliveryDataMaster.Deliveries.forEach(delivery => {
          delivery['dStatus'] = true;
          delivery['dErrorType'] = true;
          delivery['dReason'] = true;
          delivery['dAuthorization'] = true;
          delivery['dRectLabOrderNumber'] = true;
          delivery['dNotes'] = true;
        });
      }

      this.deliveryModalRef = this.modalService.open(_content, { centered: true, backdrop: "static", size: <any>'xl' });
    } catch (ex){
      console.log(ex);
    }
  }

  LoadDeliveryDetails(_content, _deliveryLabOrderNumber: string): void{
    try{
      this.blockUI.start('Cargando entrega...');
      this.deliveryService.GetDeliveriesByLabOrderNumber(_deliveryLabOrderNumber)
        .pipe(finalize(() => this.blockUI.stop()))
        .subscribe({
          next: (result) => {
            if (result.Result) {
              if(result.Data){
                let deliveriesDataMaster = result.Data.reduce((acc, cur) => {
                  acc = acc || {...cur, Deliveries: []} as IDeliveryDataMaster;
                  acc.Deliveries.push(cur);
                  return acc;
                }, null as IDeliveryDataMaster);

                this.ShowDeliveryDetails(_content, deliveriesDataMaster);
              } else {
                this.alertService.errorInfoAlert("La entrega no existe");
              }
            } else {
              console.log(result.Error);
            }
          },
          error: (err) => {
            console.log(err);
          },
        });
    } catch (ex) {
      this.blockUI.stop();
      console.log(ex);
    }
  }

  SaveDelivery(): void {
    for(let delivery of this.deliveries){
      if(delivery.U_Status == DeliveryStatus.Rectification && (delivery.U_ErrorType == DeliveryErrorType.Nothing || (delivery.U_ErrorType != DeliveryErrorType.Nothing && !delivery.U_Reason))){
        this.alertService.infoInfoAlert("Para realizar una rectificación debe seleccionar un tipo de error y una razón del error");
        return;
      }
    }

    this.blockUI.start('Actualizando entrega...');
    this.deliveryService.UpdateDeliveries(this.deliveries).pipe(finalize(()=>this.blockUI.stop())).subscribe(callBack => {
      if (callBack.Result) {
        this.deliveryModalRef.close();
        this.alertService.infoAlert("Entrega actualizada");
        this.SearchDeliveries();
      } else {
        this.alertService.errorInfoAlert('Error al cargar la lista de sedes - ' + callBack.Error.Message);
      }
    }, error => {
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    });
  }

  ChangeStatus(_event: any, _delivery: IDelivery): void {
    _delivery.U_Status = _event.target.value;
    if(_delivery.U_Status == DeliveryStatus.Rectification){
      _delivery['dErrorType'] = false;
      _delivery['dAuthorization'] = false;
      _delivery.U_Authorization = DeliveryAuthorization.Request;
    } else {
      _delivery['dErrorType'] = true;
      _delivery.U_Authorization = '';
      _delivery['dAuthorization'] = true;
    }
  }

  ChangeErrorType(_event: any, _delivery: IDelivery): void{
    _delivery.U_ErrorType = _event.target.value;
    if(_delivery.U_ErrorType != DeliveryErrorType.Nothing){
      _delivery['dReason'] = false;
    } else {
      _delivery.U_Reason = '';
      _delivery['dReason'] = true;
    }
  }

  ChangeReason(_event: any, _delivery: IDelivery): void{
    _delivery.U_Reason = _event.target.value;
  }

  ChangeAuthorization(_event: any, _delivery: IDelivery, _content, _firstValidation = true): void {
    _delivery.U_Authorization = _event.target.value;
    if(_delivery.U_Authorization != DeliveryAuthorization.Request){
      if(_firstValidation){
        this.authorizationModalMessage = `Es requerido un usuario autorizador para este proceso`;
      } else {
        this.authorizationModalMessage = `El usuario autorizador no tiene permiso para autorizar rectificaciones`;
      }

      const RECTIFICATION_PERMISSION = this.authorizatorPermissions.find(x => x.Name == 'Perm_Rectification');

      if(!RECTIFICATION_PERMISSION){
        this.RaiseAutenticationModal(_event, _delivery, _content);
      } else {
        if(_delivery.U_Authorization == DeliveryAuthorization.Approved){
          if(_delivery.U_ErrorType == DeliveryErrorType.Optometry || _delivery.U_ErrorType == DeliveryErrorType.Vendor){
            _delivery['dRectLabOrderNumber'] = false;
          }
        }
      }
    }
  }

  ResetUserCredentialsForm(){
    this.userCredentialsForm = this.formBuilder.group({
      Email: [''],
      Password: ['']
    });
  }

  RaiseAutenticationModal(_event: any, _delivery: IDelivery, _content) {
    this.ResetUserCredentialsForm();

    this.event = _event;
    this.delivery = _delivery;

    this.userCredentialsForm.patchValue({
      Email: [''],
      Password: ['']
    });

    this.authModalRef = this.modalService.open(_content, { centered: true, backdrop: "static" });
  }

  ValidateCredentials(): void {
    try {
      const EMAIL = this.userCredentialsForm.controls.Email.value;
      const PASSWORD = this.userCredentialsForm.controls.Password.value;

      this.authorizatorPermissions = [];

      this.blockUI.start(`Procesando, espere por favor`);

      this.permissionService.AuthorizerDeliveryUser(EMAIL, PASSWORD).pipe(finalize(() => this.blockUI.stop())).subscribe({
        next: (callback: IResponse<IPermission[]>) => {

          if (!callback || !callback.Result) {
            this.alertService.errorAlert(`${callback.Error.Code} - ${callback.Error.Message}`);
            return of(callback || []);
          }

          this.authorizatorPermissions = callback.Data || [];

          this.authModalRef.close();

          this.ChangeAuthorization(this.event, this.delivery, this.authModal, false);

        },
        error: error => {
          if (error && error.Error) {
            this.alertService.errorAlert(error.Error.Message);
          }
          else {
            this.alertService.errorAlert(JSON.stringify(error));
          }
        }
      });
    }
    catch (error) {
      console.info(error);
    }
  }

  ClearVoidOption(_optionsLists: { key: string, value: any}[]): { key: string, value: any}[]{
    return _optionsLists.filter(x => x.value);
  }

  // Esta funcion podria ser requerida en futuras actualizaciones
  FilterReasonsByErrorType(_optionsLists: { key: string, value: any}[]): { key: string, value: any}[]{
    switch (this.deliveryForm.controls["U_ErrorType"].value) {
      case DeliveryErrorType.Nothing:
        return _optionsLists.filter(x => [''].includes(x.value));
      case DeliveryErrorType.Laboratory:
        return _optionsLists.filter(x => ['','1','2','3','4','5','6','7'].includes(x.value));
      case DeliveryErrorType.Optometry:
        return _optionsLists.filter(x => ['','8','9','10','11','12','13'].includes(x.value));
      case DeliveryErrorType.Vendor:
        return _optionsLists.filter(x => ['','14','15','16','17','18','19','20'].includes(x.value));
      default:
        return _optionsLists;
    }
  }

  CloseAuthorizationModal(): void{
    const RECTIFICATION_PERMISSION = this.authorizatorPermissions.find(x => x.Name == 'Perm_Rectification');

    if(!RECTIFICATION_PERMISSION){
      this.delivery.U_Authorization = DeliveryAuthorization.Request;
    }

    this.authModalRef.dismiss('Cross click');
  }

  OpenHeadquarterSelector(): void {
    let modalOption: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      ariaLabelledBy: 'modal-basic-title'
    };

    let modalHeadquarter = this.modalService.open(HeadquarterSelectorComponent, modalOption);

    modalHeadquarter.result.then((result: IHeadquarter) => {
      this.headquarterService.currentHeadquarterSubject.next(result);
      this.alertService.successInfoAlert(`${result.Code} - ${result.Name} seleccionada!`);
    }, (reason: IHeadquarter) => {
      console.log(reason);
    });
  }

  PageChange(_event: any): void {
    this.SearchDeliveries(true);
  }
}
